<template>
  <div>
    <!--begin::Table-->
    <vue-good-table
      mode="remote"
      :total-rows="totalRecords"
      :is-loading="isTableLoading"
      :pagination-options="{ enabled: true }"
      :sort-options="{ enabled: false, initialSortBy: serverParams.sort }"
      :rows="rows"
      :columns="columns"
      :fixed-header="true"
      @page-change="onPageChange"
      @sort-change="onSortChange"
      @column-filter="onColumnFilter"
      @per-page-change="onPerPageChange"
      styleClass="custom-table"
    >
      <template #loading-content class="text-center">Loading...</template>
      <template #emptystate class="text-center">No entry found</template>
      <template #table-row="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <button
            v-if="props.row.invoices.length"
            @click.prevent="downloadInvoice(props.row)"
            title="Download Invoice"
            class="btn btn-secondary mr-4"
          >
            <i class="fas fa-file-download text-primary" />
            Invoice {{ props.row.invoices[0].numberFormatted }}
          </button>
        </span>

        <!-- Column: Products -->
        <span
          v-else-if="props.column.field === 'products'"
          v-html="props.formattedRow.products"
        />

        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'status'">
          <span
            :class="`badge badge-light-${statusToColorClass(props.row.status)}`"
            >{{ statusToStr(props.row.status) }}</span
          >
        </span>

        <!-- Column: Common -->
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>

      <!-- pagination -->
      <template #pagination-bottom="props">
        <div
          class="d-flex justify-content-between align-items-center flex-wrap"
        >
          <div class="d-flex align-items-center mb-0 mt-1">
            <select
              class="form-control form-control-sm me-3"
              :value="serverParams.perPage"
              @change="
                (e) => props.perPageChanged({ currentPerPage: e.target.value })
              "
            >
              <option
                v-for="(option, idx) in ['5', '10', '25', '50', '100']"
                :key="`select-perpage-${idx}`"
                :value="option"
              >
                {{ option }}
              </option>
            </select>
            <span class="text-nowrap"> orders per page </span>
          </div>
          <div>
            <ul class="pagination">
              <li
                class="page-item previous"
                :class="{ disabled: serverParams.page === 1 }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page - 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="previous"></i
                ></a>
              </li>
              <div
                v-for="idx in Math.ceil(props.total / serverParams.perPage)"
                class="d-flex flex-row align-items-center"
                :key="idx"
              >
                <li
                  class="page-item"
                  :class="{ active: serverParams.page === idx }"
                >
                  <a
                    @click.prevent="props.pageChanged({ currentPage: idx })"
                    href="#"
                    class="page-link"
                    v-if="
                      idx <= 3 ||
                      (idx >= serverParams.page - 1 &&
                        idx <= serverParams.page + 1) ||
                        (idx >= Math.ceil(props.total / serverParams.perPage) - 2)
                    "
                    >{{ idx }}</a
                  >
                </li>
                <p class="mb-0 mt-1 mx-3" v-if="(idx === serverParams.page - 2 && idx > 3) || (idx === serverParams.page + 2 && (idx < Math.ceil(props.total / serverParams.perPage) - 2))">...</p>
              </div>
              <li
                class="page-item next"
                :class="{
                  disabled:
                    serverParams.page ===
                    Math.ceil(props.total / serverParams.perPage),
                }"
              >
                <a
                  @click.prevent="
                    props.pageChanged({ currentPage: serverParams.page + 1 })
                  "
                  href="#"
                  class="page-link"
                  ><i class="next"></i
                ></a>
              </li>
            </ul>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!--end::Table-->
  </div>
</template>

<script>
import ApiService from "@/core/services/ApiService";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import OrderStatus from "@/core/constants/OrderStatus";

export default {
  name: "OperationsManager",
  data() {
    return {
      isTableLoading: false,
      totalRecords: 0,
      columns: [
        {
          label: "Products",
          field: "products",
          formatFn: (products) =>
            products
              .map((e) => `x${e.quantity} ${e.product.name}`)
              .join("<br>"),
        },
        {
          label: "Total excl. VAT",
          field: "total",
          formatFn: e => `${e} €`,
        },
        {
          label: "Status",
          field: "status",
        },
        {
          label: "Tracking no.",
          field: "trackingNumber",
        },
        {
          label: "Created",
          field: "createdAt",
          sortable: true,
          formatFn: (dt) => new Date(dt).toLocaleDateString(),
        },
        {
          label: "Updated",
          field: "updatedAt",
          sortable: true,
          formatFn: (dt) => new Date(dt).toLocaleDateString(),
        },
        {
          label: "",
          field: "action",
        },
      ],
      rows: [],
      serverParams: {
        columnFilters: {},
        sort: {
          field: "updatedAt",
          type: "desc",
        },
        page: 1,
        perPage: 25,
      },
    };
  },
  mounted() {
    this.fetchItemsFromApi();
    setCurrentPageBreadcrumbs("Orders", []);
  },
  methods: {
    updateParams(newProps) {
      this.serverParams = { ...this.serverParams, ...newProps };
    },
    onPageChange(params) {
      console.log("onPageChange", params);
      this.updateParams({ page: params.currentPage });
      this.fetchItemsFromApi();
    },
    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.onPageChange({ currentPage: 1 });
    },
    onSortChange([params]) {
      this.updateParams({
        sort: {
          field: params.field,
          type: params.type,
        },
      });
      this.onPageChange({ currentPage: 1 });
    },
    onColumnFilter(params) {
      this.updateParams(params);
      this.onPageChange({ currentPage: 1 });
    },
    /**
     * Fetch models from API and display them in the table
     */
    fetchItemsFromApi() {
      this.isTableLoading = true;

      const tableSearch = {};
      console.log(this.serverParams.columnFilters);
      Object.keys(this.serverParams.columnFilters).forEach((e) => {
        if (!this.serverParams.columnFilters[e]) return;
        tableSearch[`${e}_contains`] = this.serverParams.columnFilters[e];
      });

      const getApiParams = {
        ...tableSearch,
        limit: this.serverParams.perPage,
        page: this.serverParams.page,
        sortBy: `${this.serverParams.sort.field}:${this.serverParams.sort.type}`,
        populate: "invoices products.product",
      };

      ApiService.query("/orders", { params: getApiParams })
        .then((res) => {
          this.rows = res.data.results;
          this.totalRecords = res.data.totalResults;
          this.isTableLoading = false;
        })
        .catch((err) => {
          this.isTableLoading = false;
        });
    },
    statusToColorClass(status) {
      return OrderStatus[status] ? OrderStatus[status].colorClass : '';
    },
    statusToStr(status) {
      return OrderStatus[status] ? OrderStatus[status].label : 'N/C';
    },
    downloadInvoice(order) {
      ApiService.query(`invoices/${order.invoices[0].id}/pdf`, {
        responseType: "arraybuffer",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/pdf",
        },
      }).then(({ data }) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${order.invoices[0].numberFormatted}-neoris-invoice.pdf`); //or any other extension
        document.body.appendChild(link);
        link.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.operations-table {
  background-color: #fff;
}
</style>