export default {
    PENDING_PAYMENT_RECEIVED: {
        label: "Pending payment received",
        colorClass: "warning",
        canDownloadInvoice: false,
    },
    PENDING_APPROVAL: {
        label: "Pending approval",
        nextStatus: "IN_PROCESS",
        colorClass: "info",
        canDownloadInvoice: false,
    },
    IN_PROCESS: {
        label: "In process",
        nextStatus: "SHIPPED",
        colorClass: "info",
        canDownloadInvoice: true,
    },
    SHIPPED: {
        label: "Shipped",
        nextStatus: "DELIVERED",
        colorClass: "info",
        canDownloadInvoice: true,
    },
    PAYMENT_REJECTED: {
        label: "Cancelled - Payment rejected",
        colorClass: "danger",
        canDownloadInvoice: false,
    },
    CANCELLED: {
        label: "Cancelled",
        colorClass: "danger",
        canDownloadInvoice: false,
    },
    DELIVERED: {
        label: "Delivered",
        colorClass: "success",
        canDownloadInvoice: true,
    },
};